/* eslint-disable import/no-anonymous-default-export */
/**
 * Nosy Typography Defintions
 * 
 * Take a look at  ./src/@chakra-ui/gatsby-plugin-components/font-loader/ 
 * to see available font definitions.
 */

export default {
    fonts: {
        heading: '"Modelica-Bold", system-ui, sans-serif',
        body: '"Modelica-Regular", system-ui, sans-serif',
        lead: '"Modelica-Medium", system-ui, sans-serif',
        accent: '"Modelica-SS02-Bold", system-ui, sans-serif'
    },
    lineHeights: {
        shortest: '1.1',
        tallest: '2.1'
    }
}