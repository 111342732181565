/**
 * Nosy Chakra UI Theme Styles
 *
 * @export theme
 */


/* eslint-disable import/no-anonymous-default-export */
export default {
    styles: {
        global: ( props ) => ( {
            ':host,:root': {
                '--chakra-focus-ring-color': 'brand_secondary.500'
            },
            'html, body': {
                scrollPaddingTop: '84px',   // Desktop height of nav
                fontSize: 'md',
                color: props.colorMode === 'dark' ? 'white' : 'gray.600',
                lineHeight: 'tall',
                backgroundColor: 'brand_grayscale.black',
                scrollBehavior: 'smooth'
            },
            'body': {
                overflowX: 'hidden',
                scrollBehavior: 'smooth',
                scrollbarColor: 'brand_primary.500'
            },
            a: {
                color: props.colorMode === 'dark' ? 'brand_grayscale.white' : 'brand_grayscale.black',
            },
        } ),
    },
    shadows: {
        // This is also possible. Not sure I like inject this into
        // an existing theme section.
        // It creates a CSS variable named --chakra-shadows-focus-ring-color
        outline: '0 0 0 3px var(--chakra-ui-focus-ring-color)'
    }
}