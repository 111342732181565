/**
 * Nosy Chakra UI Theme FontLoader
 * 
 * In this component we provide preload links within the site <head> and
 * load font files via css @font-face directives.
 *
 * @export FontLoader
 */

import { Global } from '@emotion/react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from '../../../hooks/use-site-metadata'
 
const FontLoader = () => {
    const { siteUrl = '' } = useSiteMetadata()
    return (
        <React.Fragment>
            <Helmet>
                {/* Modelica ------------------------------- */}
                {/* Regular */}
                <link
                    rel='preload'
                    as='font'
                    href='/fonts/bw-modelica/woff2/BwModelica-Regular.woff2'
                    type='font/woff2'
                    crossorigin='anonymous' />
                {/* Medium */}
                <link
                    rel='preload'
                    as='font'
                    href='/fonts/bw-modelica/woff2/BwModelica-Medium.woff2'
                    type='font/woff2'
                    crossorigin='anonymous' />
                {/* Bold */}
                <link
                    rel='preload'
                    as='font'
                    href='/fonts/bw-modelica/woff2/BwModelica-Bold.woff2'
                    type='font/woff2'
                    crossorigin='anonymous' />
                {/* Modelica Stylized Set (01) ----------------- */}
                {/* Bold */}
                <link
                    rel='preload'
                    as='font'
                    href='/fonts/bw-modelica/woff2/BwModelicaSS01-Bold.woff2'
                    type='font/woff2'
                    crossorigin='anonymous' />
                {/* Modelica Stylized Set (02) ----------------- */}
                {/* Bold */}
                <link
                    rel='preload'
                    as='font'
                    href='/fonts/bw-modelica/woff2/BwModelicaSS02-Bold.woff2'
                    type='font/woff2'
                    crossorigin='anonymous' />
            </Helmet>
            <Global
                styles={`
                 /* Modelica ------------------------------- */
                 
                 /* Regular */
                 @font-face {
                     font-family: 'Modelica-Regular';
                     font-style: normal;
                     font-weight: 400;
                     font-display: swap;
                     unicode-range: U+000-5FF; /* Download only latin glyphs */
                     src:local('Modelica-Regular'), url('${siteUrl}/fonts/bw-modelica/woff2/BwModelica-Regular.woff2') format('woff2');
                 }
 
                 /* Medium */
                 @font-face {
                     font-family: 'Modelica-Medium';
                     font-style: normal;
                     font-weight: 500;
                     font-display: swap;
                     unicode-range: U+000-5FF; /* Download only latin glyphs */
                     src:local('Modelica-Medium'), url('${siteUrl}/fonts/bw-modelica/woff2/BwModelica-Medium.woff2') format('woff2');
                 }
                 
                 /* Bold */
                 @font-face {
                     font-family: 'Modelica-Bold';
                     font-style: normal;
                     font-weight: 700;
                     font-display: swap;
                     unicode-range: U+000-5FF; /* Download only latin glyphs */
                     src:local('Modelica-Bold'), url('${siteUrl}/fonts/bw-modelica/woff2/BwModelica-Bold.woff2') format('woff2');
                 }
 
                 /* End Modelica ------------------------------- */
 
 
 
                 /* Modelica Stylized Set (01) ----------------- */
 
                 /* Bold */
                 @font-face {
                     font-family: 'Modelica-SS01-Bold';
                     font-style: normal;
                     font-weight: 700;
                     font-display: swap;
                     unicode-range: U+000-5FF; /* Download only latin glyphs */
                     src:local('Modelica-SS01-Bold'),
                         url('${siteUrl}/fonts/bw-modelica/woff2/BwModelicaSS01-Bold.woff2') format('woff2');
                 }
 
                 /* End Modelica Stylized Set (01) ----------------- */
 
 
 
                 /* Modelica Stylized Set (02) ----------------- */
 
                 /* Bold */
                 @font-face {
                     font-family: 'Modelica-SS02-Bold';
                     font-style: normal;
                     font-weight: 700;
                     font-display: swap;
                     unicode-range: U+000-5FF; /* Download only latin glyphs */
                     src:local('Modelica-SS02-Bold'),
                         url('${siteUrl}/fonts/bw-modelica/woff2/BwModelicaSS02-Bold.woff2') format('woff2');
                 }
 
                 /* End Modelica Stylized Set (02) ------------- */
             `} />
        </React.Fragment>
    )
}
export default FontLoader
 