/* eslint-disable import/no-anonymous-default-export */
/**
 * Nosy Chakra UI Heading styles
 *
 * @export default
 */
export default {
    // Styles for the base style
    baseStyle: {
        fontFamily: 'Modelica-Bold',
        fontWeight: 'bold',
        letterSpacing: ['tighter', 'tight'],
        lineHeight: 'shorter',
        // color: 'brand_grayscale.white'
    },
    // Styles for the size variations
    // sizes: {},
    // Styles for the visual style variations
    variants: {},
    // The default `size` or `variant` values
    // defaultProps: {}
}