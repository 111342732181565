/* eslint-disable import/no-anonymous-default-export */
/**
 * Nosy Chakra UI Theme Colors
 * 
 * Color scales generated form https://smart-swatch.netlify.app/
 *
 * @export default
 */

export default {
    colors: {
        'brand_primary': {
            50: '#dbfffa',
            100: '#afffef',
            200: '#80ffe5',
            300: '#51ffdb',
            400: '#2dfed0',
            500: '#1ee5b8',
            600: '#0eb38e',
            700: '#008066', // * default
            800: '#004d3d',
            900: '#001c13'
        },
        'brand_secondary' : {
            50: '#ddfef7',
            100: '#b8f4e6',
            200: '#91ead6',
            300: '#68e1c6',
            400: '#40d8b5',
            500: '#27bf9c', // * default
            600: '#199479',
            700: '#0c6a57',
            800: '#004133',
            900: '#001710',
        },
        'brand_contrast': {
            50: '#ffeedc',
            100: '#ffd0b0',
            200: '#fcb282',
            300: '#f99452',
            400: '#f77522', // * default
            500: '#dd5c08',
            600: '#ad4705',
            700: '#7c3202',
            800: '#4c1d00',
            900: '#200700',
        },
        brand_grayscale: {
            white: '#FFFCF6',
            lightest: '#f4f4f4',
            lighter: '#f7f7f7',
            light: '#f1f1f1',
            mid: '#808080',
            dark: '#333333',
            darker: '#121212',
            darkest: '#0a0a0a',
            black: '#000309'
        }
    }
}
