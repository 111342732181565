/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import FontLoader from './src/@chakra-ui/gatsby-plugin/components/font-loader'
// import theme from './src/@chakra-ui/gatsby-plugin/theme'
import { Party } from './src/components/easter-eggs'

export const wrapRootElement = ( { element } ) => {
    return (
        <React.Fragment>
            <FontLoader />
            <Party />
            {element}
        </React.Fragment>
    )
}
