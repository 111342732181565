/* eslint-disable import/no-anonymous-default-export */
/**
 * Nosy Chakra UI Button styles
 *
 * @export default
 */
import { transparentize } from '@chakra-ui/theme-tools'

export default {
    // Styles for the base style
    // baseStyle: {},
    // Styles for the size variations
    // sizes: {},
    // Styles for the visual style variations
    variants: {
        brand: {
            bg: 'brand_secondary.500',
            color: 'gray.100',
            borderRadius: 'full',
            lineHeight: 'normal',
            fontFamily: 'lead',
            py: 7,
            px: 8,
            _hover: {
                textDecoration: 'none',
                backgroundColor: 'brand_secondary.600'
            }
        },
        'brand-outline': {
            backgroundColor: 'transparent',
            borderRadius: 'full',
            border: '2px solid',
            borderColor: 'brand_secondary.500',
            color: 'gray.100',
            textDecoration: 'none',
            lineHeight: 'normal',
            fontFamily: 'lead',
            py: 7,
            px: 8,
            _hover: {
                textDecoration: 'none',
                color: 'gray.100',
                backgroundColor: 'brand_secondary.500'
            }
        },
        /**
         * Borked - "spin circle" https://codepen.io/giana/pen/yYBpVY
         */
        // 'brand-animated-outline': {
        //     backgroundColor: 'transparent',
        //     boxSizing: 'border-box',
        //     borderRadius: 'full',
        //     boxShadow: 'none',
        //     color: 'gray.100',
        //     textDecoration: 'none',
        //     lineHeight: 'normal',
        //     fontFamily: 'lead',
        //     py: 7,
        //     px: 8,
        //     position: 'relative',
        //     _before: {
        //         borderRadius: 'full',
        //         boxSizing: 'inherit',
        //         content: '""',
        //         position: 'absolute',
        //         width: '100%',
        //         height: '100%',
        //         border: '2px solid transparent',
        //         top: 0,
        //         left: 0
        //     },
        //     _after: {
        //         borderRadius: 'full',
        //         boxSizing: 'inherit',
        //         content: '""',
        //         position: 'absolute',
        //         width: '100%',
        //         height: '100%',
        //         border: '0 solid transparent',
        //         top: 0,
        //         left: 0
        //     },
        //     _hover: {
        //         textDecoration: 'none',
        //         color: 'gray.500',
        //         '::before': {
        //             borderTopColor: 'red', // Show borders
        //             borderRightColor: 'red',
        //             borderBottomColor: 'red',
        //             // Stagger border appearances
        //             transition:
        //               'border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s'
        //         },
        //         '::after': {
        //             borderTop: '2px solid red',     // Shows border
        //             borderLeftWidth: '2px',         // Solid edges, invisible borders
        //             borderRightWidth: '2px',        // Solid edges, invisible borders
        //             transform: 'rotate(270deg)',    // Rotate around circle
        //             // Solid edge post-rotation
        //             transition: 'transform 0.4s linear 0s, border-left-width 0s linear 0.35s'
        //         }
        //     }
        // },
        /**
         * Borked - "Draw" https://codepen.io/giana/pen/yYBpVY
         */
        // 'brand-animated-outline': {
        //     backgroundColor: 'transparent',
        //     boxSizing: 'border-box',
        //     borderRadius: 'full',
        //     boxShadow: 'inset 0 0 0 2px transparent',
        //     color: 'gray.100',
        //     textDecoration: 'none',
        //     lineHeight: 'normal',
        //     fontFamily: 'lead',
        //     py: 7,
        //     px: 8,
        //     position: 'relative',
        //     _before: {
        //         borderRadius: 'full',
        //         boxSizing: 'inherit',
        //         content: '""',
        //         position: 'absolute',
        //         border: '2px solid transparent',
        //         width: 0,
        //         height: 0,
        //         top: 0,
        //         left: 0
        //     },
        //     _after: {
        //         borderRadius: 'full',
        //         boxSizing: 'inherit',
        //         content: '""',
        //         position: 'absolute',
        //         border: '2px solid transparent',
        //         width: 0,
        //         height: 0,
        //         bottom: 0,
        //         right: 0,
        //     },
        //     _hover: {
        //         textDecoration: 'none',
        //         color: 'gray.500',
        //         '::before': {
        //             width: '100%',
        //             height: '100%',
        //             borderTopColor: 'brand_secondary.500',      // Make borders visible
        //             borderRightColor: 'brand_secondary.500',
        //             // Width expands first - And then height
        //             transition: 'width 0.25s ease-out, height 0.25s ease-out 0.25s'
        //         },
        //         '::after': {
        //             width: '100%',
        //             height: '100%',
        //             borderBottomColor: 'brand_secondary.500',   // Make borders visible
        //             borderLeftColor: 'brand_secondary.500',
        //             // Wait for ::before to finish before showing border, And then exanding width, And finally height
        //             transition: 'border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s'
        //         }
        //     }
        // },
        'brand-ghost': {
            backgroundColor: 'transparent',
            borderRadius: 'full',
            border: '2px solid',
            borderColor: 'transparent',
            color: 'brand_secondary.500',
            fontFamily: 'lead',
            py: 7,
            px: 8,
            _hover: {
                textDecoration: 'none',
                color: 'brand_secondary.500',
                backgroundColor: transparentize( 'brand_secondary.500', 0.125 ),
            }
        },
        'brand-link': {
            color: 'gray.100',
            fontFamily: 'lead',
            py: 7,
            px: 8,
            _hover: {
                textDecoration: 'none',
                color: 'brand_secondary.500'
            }
        }
    },
    // The default `size` or `variant` values
    // defaultProps: {}
}
