/* eslint-disable import/no-anonymous-default-export */
/**
 * Nosy Chakra UI Link styles
 *
 * @export default
 */

export default {
    // Styles for the base style
    baseStyle: {
        _focus: { 
            boxShadow: 'none' 
        }
    },
    // Styles for the size variations
    // sizes: {},
    // Styles for the visual style variations
    //  variants: {},
    // The default `size` or `variant` values
    // defaultProps: {}
}
 