/* eslint-disable import/no-anonymous-default-export */
/**
 * Nosy Chakra UI Text styles
 *
 * @export default
 */
export default {
    // Styles for the base style
    baseStyle: {
        fontFamily: 'Modelica-Regular',
        fontWeight: 'normal',
        // letterSpacing: ['tighter', 'tight', 'normal'],
        // lineHeight: 'shorter'
    },
    // Styles for the size variations
    // sizes: {},
    // Styles for the visual style variations
    variants: {},
    // The default `size` or `variant` values
    // defaultProps: {}
}