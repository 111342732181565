/**
 * Nosy Party Easter Egg
 *
 * @export Party
 */
// import { useToken } from '@chakra-ui/react'
import React, { useState } from 'react'
import Confetti from 'react-confetti'
import { useTimeout, useWindowSize } from 'react-use'
import useKonami from 'react-use-konami'
import colors from '../../@chakra-ui/gatsby-plugin/colors'

const Party = () => {
    // Hold on/off value in local state
    const [ partyTime, setPartyTime ] = useState( false )

    // Provide dimensions and respond to resize events
    const { width, height } = useWindowSize()

    // Get Brand colours from Chakra UI colors object
    const { colors: { brand_primary = {} } } = colors

    // Create array of string values from theme object
    const brandColorScheme = Object.values( brand_primary )

    // Set timeout (milliseconds) to finish party time - no clean up
    const [ isComplete ] = useTimeout( 15000 )

    /**
     * Takes two parameters:
     * handler: () => any
     * options?: {code: string[]}
     *
     * the default code is:
     *  ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', 'Enter']
     */
    useKonami(
        () => {
            setPartyTime( true )
        },
        {
            code: ['ArrowUp', 'ArrowDown', 'Enter']
            // code: ['p', 'a', 'r', 't', 'y']
            // code: ['KeyP', 'KeyA', 'KeyR', 'KeyT', 'KeyY']
        }
    )

    return (
        <React.Fragment>
            {partyTime ? (
                <Confetti
                    colors={[...brandColorScheme, '#F04939', '#362066', '#FFD700' ]}
                    numberOfPieces={343}
                    friction={0.99}
                    wind={0}
                    gravity={0.12}
                    initialVelocityX={4}
                    initialVelocityY={10}
                    opacity={1.0}
                    recycle={!isComplete()}
                    width={width}
                    height={height} />
            ) : null}
        </React.Fragment>
    )
}

export default Party
