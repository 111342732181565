exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-discipline-discipline-js": () => import("./../../../src/templates/discipline/discipline.js" /* webpackChunkName: "component---src-templates-discipline-discipline-js" */),
  "component---src-templates-discipline-index-js": () => import("./../../../src/templates/discipline/index.js" /* webpackChunkName: "component---src-templates-discipline-index-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/landing-page/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page/about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page/contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-frontpage-js": () => import("./../../../src/templates/page/frontpage.js" /* webpackChunkName: "component---src-templates-page-frontpage-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-team-index-js": () => import("./../../../src/templates/team/index.js" /* webpackChunkName: "component---src-templates-team-index-js" */),
  "component---src-templates-team-team-js": () => import("./../../../src/templates/team/team.js" /* webpackChunkName: "component---src-templates-team-team-js" */),
  "component---src-templates-work-index-js": () => import("./../../../src/templates/work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */),
  "component---src-templates-work-work-js": () => import("./../../../src/templates/work/work.js" /* webpackChunkName: "component---src-templates-work-work-js" */)
}

