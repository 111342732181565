/**
 * Nosy Chakra UI Theme
 *
 * @export theme
 */


import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import button from './components/button'
import heading from './components/heading'
import link from './components/link'
import text from './components/text'
import styles from './styles'
import typography from './typography'

const theme = extendTheme( {
    ...styles,
    ...colors,
    ...typography,
    components: {
        Heading: heading,
        Text: text,
        Button: button,
        Link: link
    },
    config: {
        initialColorMode: 'dark'
    }
} )

export default theme
